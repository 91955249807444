import React, { useEffect, useState } from 'react';
import { useAudioPlayer } from 'react-use-audio-player';

const AudioPlayer = () => {
    const [music, setMusic] = useState<boolean>(false);
    const { togglePlayPause, playing } = useAudioPlayer({
        src: './music.mp3',
        format: 'mp3',
        autoplay: true,
        playing: false,
        html5: true,
        loop: true,
    });

    useEffect(() => {
        if (playing === false) {
            togglePlayPause();
        }
    }, []);

    return (
        <div
            className="music-loader"
            onClick={() => {
                togglePlayPause();
                setMusic(!music);
            }}
        >
            <div className={music ? `bar bar-1` : `musiccstate bar bar-1 `}></div>
            <div className={music ? `bar bar-2` : `musiccstate bar bar-2 `}></div>
            <div className={music ? `bar bar-3` : `musiccstate bar bar-3 `}></div>
            <div className={music ? `bar bar-4` : `musiccstate bar bar-4 `}></div>
            <div className={music ? `bar bar-5` : `musiccstate bar bar-5 `}></div>
            <div className={music ? `bar bar-6` : `musiccstate bar bar-6 `}></div>
            <div className={music ? `bar bar-7` : `musiccstate bar bar-7 `}></div>
            <div className={music ? `bar bar-8` : `musiccstate bar bar-8 `}></div>
        </div>
    );
};
export default AudioPlayer;
