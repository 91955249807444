import Router from 'next/router';
import nProgress from 'nprogress';
import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import '../styles/globals.scss';
import '../styles/nprogress.scss';
import Script from 'next/script';
import ParticleBackground from '../components/ParticleBackground';
import { ThemeProvider } from 'next-themes';
import { AnimatePresence } from 'framer-motion';
import StateProvider from './contexts/StateContext';
import { Loader } from '../components/Loader';
import { AudioPlayerProvider } from 'react-use-audio-player';
Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.error);
Router.events.on('routeChangeComplete', nProgress.done);
import AudioPlayer from '../components/AudioPlayer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*
 * This is home page!
 * @returns {JSX.Element}
 */

const MyApp = (props: any) => {
    const { Component, pageProps, router } = props;
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [spinernone, setSpinernone] = useState<string>('');
    const notify = () => toast('Welcome to my Portofolio');
    useEffect(() => {
        if (location.protocol !== 'https:' && location.host !== 'localhost:3000') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }
        setTimeout(() => {
            if (location.host !== 'localhost:3000') {
                console.clear();
            }
        }, 1000);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setFirstLoad(false);
            notify();
        }, 2500);
        setTimeout(() => {
            setSpinernone('dispNoneSpinner');
        }, 2000);
    }, []);

    return (
        <>
            <StateProvider>
                <Script
                    strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=G-3CSB7HWDRS`}
                />
                <Script id="google-analytics" strategy="afterInteractive">
                    {`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', 'G-3CSB7HWDRS');
				`}
                </Script>
                <Script type="application/ld+json" id="structured-data" strategy="afterInteractive">
                    {`
  {
    "@context": "http://schema.org",
    "@type": "Person",
    "name": "Daniel Cela",
    "jobTitle": "Software Engineer",
    "image": "https://www.danielcela.com/images/daniel_profile.jpg",
    "url": "https://www.danielcela.com",
    "sameAs": [
      "https://www.linkedin.com/in/celadaniel",
      "https://github.com/CelaDaniel"
    ],
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Tirana",
      "addressRegion": "Albania"
    }
  }
  `}
                </Script>

                <ThemeProvider attribute="class">
                    <div
                        className="grid grid-cols-12 gap-6 px-5 bigGridProgile lg:mb-0 md:mb-16 sm:px-20 md:px-32 lg:px-36 xl:px-36 main-div-fix-res"
                        style={{}}
                    >
                        <ParticleBackground />
                        <div className="h-full col-span-12 p-4 text-base text-center realProfile bg-white-rgba dark:bg-black-rgba lg:col-span-3 rounded-2xl shadow-custom-light dark:shadow-custom-dark border-top-mains-div div2-div-submain">
                            <Sidebar />
                        </div>
                        <div className="flex flex-col col-span-12 overflow-hidden contentSideRight bg-white-rgba shadow-custom-light dark:shadow-custom-dark rounded-2xl lg:col-span-9 dark:bg-black-rgba border-top-mains-div div2-div-submain">
                            <ToastContainer
                                toastStyle={{
                                    backgroundColor: 'rgba(8, 15, 33, 0.7882352941)',
                                    color: '#00f260',
                                }}
                                className="toast-container"
                                position="top-center"
                                toastClassName="dark-toast"
                            />
                            <Navbar />
                            <AnimatePresence exitBeforeEnter>
                                <Component {...pageProps} key={router.router} />
                            </AnimatePresence>
                        </div>
                    </div>
                    <div
                        className={`glass ` + spinernone}
                        style={firstLoad ? { display: 'flex' } : { display: 'none' }}
                    >
                        <Loader />
                    </div>
                    <AudioPlayerProvider>
                        <AudioPlayer />
                    </AudioPlayerProvider>
                </ThemeProvider>
            </StateProvider>
        </>
    );
};

export default MyApp;
